import {
	Card,
	Container,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import HideIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

type Props = {
	list: List;
	open: boolean;
	onClose: () => any;
};
function ItemsModal({ open, list, onClose }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Modal open={open} className={classes.modal} onClose={onClose}>
			<Card className={classes.modalCard}>
				<Container className={classes.modalHeader}>
					<CustomTooltip aria-label={t('common:view')} title={t('common:view')} onClick={onClose} icon={HideIcon} />
				</Container>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t('common:image')}</TableCell>
							<TableCell>ISBN</TableCell>
							<TableCell>{t('common:code')} (KEL)</TableCell>
							<TableCell>{t('common:title')}</TableCell>
							<TableCell>{t('common:listMessage')}</TableCell>
							<TableCell>{t('common:amount')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list.items.map((item) => {
							return (
								<TableRow key={`books-${item._id}`}>
									<TableCell>
										<img src={item.thumbnail} alt={''} width={50} height={50} />
									</TableCell>
									<TableCell>{item.isbn}</TableCell>
									<TableCell>{item.kelCode}</TableCell>
									<TableCell>{item.title}</TableCell>
									<TableCell>{list.message || ''}</TableCell>
									<TableCell>{list.amounts?.[item._id!] || list.studentsAmount}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Card>
		</Modal>
	);
}

export default ItemsModal;

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			margin: 'auto',
			width: '80%',
			height: '50em',
			overflow: 'scroll',
		},
		modalCard: {},
		modalHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingTop: '4px',
			maxWidth: 'unset',
		},
	})
);
