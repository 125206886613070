import { useReducer, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
	Box,
	createStyles,
	DialogActions,
	FormHelperText,
	IconButton,
	LinearProgress,
	makeStyles,
	Typography,
} from '@material-ui/core';

import ImportIcon from '@material-ui/icons/CloudUploadRounded';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useTranslation } from 'react-i18next';

import { reducer, defaultState, isImportErrors } from './reducer';
import UploadInput from 'components/UploadInput';
import { getStringFromData } from 'lib/helpers';

type UploadProps<T extends Object = any> = {
	openDialog: boolean;
	onCancel(): void;
	onEnded(response: ParseCsvFileResult<T> | null): void;
	importData: (file: File) => Promise<ParseCsvFileResult<T>>;
	id: string;
	name: string;
	accept: string;
	warning?: string;
	exampleFile?: string;
	errorKey?: string;
	errorKeyName?: string;
};

function ItemsUpload({
	openDialog,
	importData,
	onCancel,
	onEnded,
	id,
	name,
	accept,
	warning: warningMessage,
	exampleFile,
	errorKeyName,
	errorKey = '_id',
}: UploadProps) {
	const { t } = useTranslation();

	const classes = useStyles();

	const [{ importing, error, errors, warning, file, imported, response }, dispatch] = useReducer(reducer, defaultState);

	const reset = useCallback(function cancel() {
		dispatch({ type: 'Reset' });
		dispatch({ type: 'SetFile', payload: null });
	}, []);

	const cancel = useCallback(
		function cancel() {
			reset();
			onCancel();
		},
		[reset, onCancel]
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = event.target;
			if (files && files[0] && files[0].name && (files[0].name.includes('zip') || files[0].name.includes('csv'))) {
				dispatch({ type: 'SetFile', payload: files[0] });
			} else {
				dispatch({ type: 'SetError', payload: t('common:fileError') });
			}
		},
		[t]
	);

	const onAccept = useCallback(async () => {
		if (file) {
			try {
				dispatch({ type: 'SetImporting', payload: true });
				const importRes = await importData(file);
				if (importRes.success) {
					dispatch({ type: 'SetResponse', payload: importRes });
					dispatch({ type: 'SetImported', payload: true });
				} else {
					dispatch({ type: 'SetImported', payload: true });
					if (isImportErrors(importRes.errors)) {
						dispatch({ type: 'SetErrors', payload: importRes.errors });
					}
					dispatch({
						type: 'SetError',
						payload: !!importRes.error
							? importRes.error
							: t('common:followingElementsWithError', {
									key: 'isbn',
									elements: importRes.errors
										.map((error) => (typeof error === 'string' ? error : error._id || getStringFromData(error)))
										.join(','),
									error: importRes.error || 'unknown error',
							  }),
					});
				}
				dispatch({ type: 'SetWarning', payload: importRes.warning });
			} catch (err) {
				const error = err as unknown as any;
				console.warn(error);
				dispatch({ type: 'SetImported', payload: true });
				dispatch({ type: 'SetError', payload: error && error.message ? error.message : `${error || 'unknown error'}` });
			}
		}
	}, [t, file, importData]);

	const ended = useCallback(() => {
		dispatch({ type: 'Reset' });
		onEnded(response);
	}, [onEnded, response]);

	return (
		<Dialog open={openDialog} onClose={() => cancel()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t('common:import')}</DialogTitle>

			<DialogContent>
				{!!warning && <FormHelperText className={classes.warningMessage}>{warning}</FormHelperText>}
				{!!error && <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>}
				{errors.map((error, index) => (
					<FormHelperText key={`error-${index}`} className={classes.errorMessage}>
						{`${errorKeyName ? `(${errorKeyName}) ` : ''}`}
						{error.element[errorKey] || `position  (${index + 1})`}: {error.error}
					</FormHelperText>
				))}
				{imported && <FormHelperText className={classes.successMessage}>{t('common:importSuccess')}</FormHelperText>}

				{importing && <LinearProgress variant={'indeterminate'} />}

				<Box>
					<DialogContentText>{t('common:selectFile')}</DialogContentText>
					<UploadInput
						id={id}
						name={name}
						accept={accept}
						fileName={file?.name || ''}
						onChange={onChange}
						disabled={importing || !!error || imported}
						actionDisabled={importing || imported}
					/>
					{importing && <Typography variant="h6">{t('common:importingPleaseWait')}</Typography>}
				</Box>

				{!!warningMessage && <FormHelperText className={classes.warningMessage}>{warningMessage}</FormHelperText>}

				{!!exampleFile && (
					<a href={exampleFile} target="_blank" rel="noreferrer">
						{t('common:exampleFile')}
					</a>
				)}
			</DialogContent>

			<DialogActions>
				{!imported && (
					<IconButton onClick={cancel} disabled={imported || importing}>
						<HighlightOffIcon fontSize="large" />
					</IconButton>
				)}
				{!imported && !error && errors.length === 0 && (
					<IconButton onClick={onAccept} disabled={!file || importing}>
						<ImportIcon fontSize="large" />
					</IconButton>
				)}
				{imported && (
					<IconButton onClick={ended}>
						<CheckCircleOutlineIcon fontSize="large" />
					</IconButton>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default ItemsUpload;

const useStyles = makeStyles((theme) =>
	createStyles({
		errorMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.error.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
		warningMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.warning.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
		successMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.success.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
	})
);
