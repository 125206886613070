import { TableCell } from '@material-ui/core';
import moment from 'moment';
import Actions from './Actions';

type Props = {
	setShowing: React.Dispatch<React.SetStateAction<boolean>>;
	onSelect?: (list: List) => any;
	list: List;
	onRefetch: () => any;
};

function ListCells({ list, setShowing, onSelect, onRefetch }: Props) {
	return (
		<>
			<TableCell>{list.name}</TableCell>
			<TableCell>{list.author?.email}</TableCell>
			{/* <TableCell>{moment(list.createdAt).format('DD/MM/yyyy HH:mm')}</TableCell> */}
			<TableCell>{moment(list.updatedAt).format('DD/MM/yyyy HH:mm')}</TableCell>
			<Actions list={list} setShowing={setShowing} onSelect={onSelect} onRefetch={onRefetch} />
		</>
	);
}

export default ListCells;
