import common from './common';
import general from './general';
import system from './system';
import users from './users';
import schools from './schools';
import items from './items';
import lists from './lists';
import components from './components';
import subjects from './subjects';
import certificates from './certificates';
import curriculums from './curriculums';
import series from './series';
import itemTypes from './itemTypes';
import cycles from './cycles';
import genres from './genres';
import formats from './formats';

const es = {
	common,
	general,
	system,
	users,
	schools,
	items,
	lists,
	components,
	subjects,
	certificates,
	curriculums,
	series,
	itemTypes,
	cycles,
	genres,
	formats,
} as const;

export default es;
