import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';
import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';
import Schools from 'components/screens/Schools';
import SchoolsEdition from 'components/screens/SchoolEdition';
import Teachers from 'components/screens/Teachers';
import TeachersEdition from 'components/screens/TeacherEdition';
import Items from 'components/screens/Items';
import ItemEdition from 'components/screens/ItemEdition';
import ListEdition from 'components/screens/ListEdition';
import Lists from 'components/screens/Lists';
import Components from 'components/screens/Components';
import Subjects from 'components/screens/Subjects';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import useLogoutOnTokenExpired from './hooks/useLogoutOnTokenExpired';
import Certificates from 'components/screens/Certificates';
import Curriculums from 'components/screens/Curriculums';
import Series from 'components/screens/Series';
import Genres from 'components/screens/Genres';
import ItemTypes from 'components/screens/ItemTypes';
import Formats from 'components/screens/Formats';
import Cycles from 'components/screens/Cycles';

function Router() {
	// If token has expired, logout user
	useLogoutOnTokenExpired();

	return (
		<BrowserRouter>
			<Switch>
				<PrivateRoute key="route-me" path="/me" component={UsersEdition} rules={['Profile']} exact={true} />

				<PrivateRoute key="route-users-edit" path="/users/:id" component={UsersEdition} rule="UserFull" exact={true} />
				<PrivateRoute key="route-users" path="/users" component={Users} rule="UserFull" exact={true} />

				<PrivateRoute
					key="route-teachers-edit"
					path="/teachers/:id"
					component={TeachersEdition}
					rule="TeachersEdition"
					exact={true}
				/>
				<PrivateRoute key="route-teachers" path="/teachers" component={Teachers} rule="TeachersEdition" exact={true} />

				<PrivateRoute
					key="route-school-edit"
					path="/schools/:id"
					component={SchoolsEdition}
					rule="SchoolEdition"
					exact={true}
				/>
				<PrivateRoute key="route-schools" path="/schools" component={Schools} rule="SchoolEdition" exact={true} />

				<PrivateRoute key="route-items-edit" path="/items/:id" component={ItemEdition} rule="ItemsFull" exact={true} />
				<PrivateRoute key="route-items" path="/items" component={Items} rule="ItemsFull" exact={true} />

				<PrivateRoute key="route-lists-edit" path="/lists/:id" component={ListEdition} rule="ListsFull" exact={true} />
				<PrivateRoute key="route-lists" path="/lists" component={Lists} rule="ListsFull" exact={true} />
				<PrivateRoute
					key="route-components"
					path="/components"
					component={Components}
					exact={true}
					rule="ComponentFull"
				/>
				<PrivateRoute key="route-subjects" path="/subjects" component={Subjects} exact={true} rule="SubjectsFull" />
				<PrivateRoute
					key="route-certificates"
					path="/certificates"
					component={Certificates}
					exact={true}
					rule="CertificatesFull"
				/>
				<PrivateRoute
					key="route-curriculums"
					path="/curriculums"
					component={Curriculums}
					exact={true}
					rule="CurriculumsFull"
				/>
				<PrivateRoute key="route-genres" path="/genres" component={Genres} exact={true} rule="GenresFull" />
				<PrivateRoute key="route-cycles" path="/cycles" component={Cycles} exact={true} rule="CyclesFull" />
				<PrivateRoute key="route-formats" path="/formats" component={Formats} exact={true} rule="FormatsFull" />
				<PrivateRoute key="route-series" path="/series" component={Series} exact={true} rule="SeriesFull" />
				<PrivateRoute key="route-item-types" path="/item-types" component={ItemTypes} exact={true} rule="ItemsFull" />

				{/* SYSTEM */}
				<PrivateRoute key="route-dashboard" path="/dashboard" component={Dashboard} exact={true} />
				<PrivateRoute key="route-inbox" path="/inbox" component={Inbox} exact={true} />
				<PublicRoute key="route-login" path="/login" component={Login} exact={true} />
				<PrivateRoute key="route-home" path="/" component={Dashboard} exact={true} />

				<Route component={NoMatch} />
			</Switch>
		</BrowserRouter>
	);
}

export default Router;
