import { TableCell, createStyles, makeStyles } from '@material-ui/core';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';

import EditIcon from '@material-ui/icons/Edit';
import ApproveIcon from '@material-ui/icons/ThumbUp';
import ArchiveIcon from '@material-ui/icons/Archive';
import ViewIcon from '@material-ui/icons/RemoveRedEye';

import { updateList } from 'lib/models/lists';

type Props = {
	setShowing: React.Dispatch<React.SetStateAction<boolean>>;
	onSelect?: (list: List) => any;
	list: List;
	onRefetch: () => any;
};

function Actions({ setShowing, onSelect, list, onRefetch }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const onClickProps = onSelect ? { onClick: () => onSelect(list) } : {};

	const approve = async () => {
		await updateList({ ...list, status: 'kelApproved' });
		onRefetch();
	};

	const archive = async () => {
		await updateList({ ...list, status: 'archived' });
		onRefetch();
	};

	return (
		<TableCell className={classes.actions}>
			<CustomTooltip
				aria-label={t('common:view')}
				title={t('common:view')}
				onClick={() => setShowing((current) => !current)}
				icon={ViewIcon}
			/>
			<CustomTooltip aria-label={t('common:edit')} title={t('common:edit')} {...onClickProps} icon={EditIcon} />
			<CustomTooltip
				aria-label={t('common:approve')}
				title={t(isNaN(list.studentsAmount as number) ? 'common:amountRequired' : 'common:approve')}
				onClick={approve}
				icon={() => (
					<ApproveIcon
						color={list.status === 'kelApproved' || isNaN(list.studentsAmount as number) ? 'disabled' : 'primary'}
					/>
				)}
				disabled={list.status === 'kelApproved' || isNaN(list.studentsAmount as number)}
			/>
			<CustomTooltip
				aria-label={t('common:archive')}
				title={t('common:archive')}
				onClick={archive}
				disabled={list.status === 'archived'}
				icon={() => <ArchiveIcon color={list.status === 'archived' ? 'disabled' : 'error'} />}
			/>
		</TableCell>
	);
}

export default Actions;

const useStyles = makeStyles((theme) =>
	createStyles({
		actions: {
			display: 'flex',
			flexDirection: 'row',
		},
	})
);
