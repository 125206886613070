import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Typography,
	Box,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Container,
	Modal,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	CircularProgress,
	Button,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import ExportIcon from '@material-ui/icons/CloudDownload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import CloseRoundIcon from '@material-ui/icons/ClearRounded';

import Papa from 'papaparse';

import SearchToolbar from 'components/SearchToolbar';
import PaperContainer from 'components/containers/PaperContainer';
import InputContainer from 'components/containers/InputContainer';

import useSearchFilters from 'hooks/useSearchFilters';
import useSchools from 'hooks/useSchools';
import useQuery from 'hooks/useQuery';
import useUpdateQuery from 'hooks/useUpdateQuery';

import useLists from './useLists';
import { ListStatus, listStatus } from 'teachers-types';
import Schools, { SchoolLists } from './Schools';

import { levelsFilters } from 'types/constants';
import Levels, { LevelLists, LevelSchoolLists } from './Levels';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import JSZip from 'jszip';
import saveAs from 'file-saver';

const defaultFilter: ListSearchFilters = {};

type Props = {
	loading?: boolean;
	onSelect?(list: List): any;
};

type ExportFile = { name: string; file: string };

export default function ListTable(props: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const query = useQuery();
	const queryStatus = query.get('status');

	const [showExportModal, setShowExportModal] = React.useState(false);
	const [exportLoading, setExportLoading] = React.useState(false);
	const [csvFiles, setCsvFiles] = React.useState<ExportFile[]>([]);
	const { loading: loadingSchools, schools } = useSchools();
	const [status, setStatus] = React.useState<ListStatus>(isValidStatus(queryStatus) ? queryStatus : 'approved');
	const [school, setSchool] = useState<string | null>(query.get('school') || null);
	const [level, setLevel] = useState<string>(query.get('level') || '');
	const [groupBy, setGroupBy] = useState<string>(query.get('groupBy') || 'school');
	const [from, setFrom] = useState<Moment | null | undefined>(moment(query.get('from') || new Date()).startOf('day'));
	const [to, setTo] = useState<Moment | null | undefined>(
		moment(query.get('to') || new Date())
			.add(1, 'day')
			.endOf('day')
	);

	const { filters, setText } = useSearchFilters(defaultFilter);

	const textFromQuery = query.get('text');

	const { loading: loadingLists, lists, refetch } = useLists(school, status, level, filters.text, from, to);

	const loading = loadingSchools || loadingLists || exportLoading;

	useEffect(() => {
		if (textFromQuery) {
			setText(textFromQuery);
		}
	}, [setText, textFromQuery]);

	const params = React.useMemo(
		() => ({ status, school, level, groupBy, text: filters.text, from: from?.toISOString(), to: to?.toISOString() }),
		[status, level, school, groupBy, filters.text, from, to]
	);
	useUpdateQuery(params);

	const disabled = loading;

	const data: LevelLists[] | SchoolLists[] =
		groupBy === 'level'
			? lists.reduce((all, list) => {
					const level = list.level || '';
					const index = all.findIndex((current) => current.name === level);
					const school = schools.find((current) => current.hubspotId === list.schoolHubspotId);
					const schoolList: LevelSchoolLists = {
						id: list.schoolHubspotId || '',
						name: school?.name ?? (list.schoolName || ''),
						lists: [list],
					};
					if (index >= 0) {
						const schoolIndex = all[index]!.schools.findIndex((current) => current.id === list.schoolHubspotId);
						if (schoolIndex >= 0) {
							all[index]!.schools[schoolIndex]!.lists.push(list);
						} else {
							all[index]!.schools.push(schoolList);
						}
						return all;
					}
					const levelLists: LevelLists = {
						name: level,
						schools: [schoolList],
					};
					return [...all, levelLists];
			  }, [] as LevelLists[])
			: lists.reduce((all, list) => {
					const school = schools.find((current) => current.hubspotId === list.schoolHubspotId);
					const index = all.findIndex((current) => current.id === list.schoolHubspotId);
					const name = list.level || '';
					if (index >= 0) {
						const levelIndex = all[index]!.levels.findIndex((current) => current.name === name);
						if (levelIndex >= 0) {
							all[index]!.levels[levelIndex]!.lists.push(list);
						} else {
							all[index]!.levels.push({ name, lists: [list] });
						}

						return all;
					}
					const schoolList: SchoolLists = {
						id: list.schoolHubspotId || '',
						name: school?.name ?? (list.schoolName || ''),
						levels: [{ name, lists: [list] }],
					};
					return [...all, schoolList];
			  }, [] as SchoolLists[]);

	const closeModal = useCallback(() => {
		setExportLoading(false);
		setShowExportModal(false);
	}, []);

	const confirmExport = useCallback(async () => {
		setExportLoading(true);
		try {
			const files = lists.map((list) => {
				const csv = Papa.unparse(list.items);

				return {
					name: list.name,
					file: csv,
				};
			});
			setCsvFiles(files);
			setExportLoading(false);

			return;
		} catch (error) {
			console.warn(error);
		}
	}, [lists]);

	const exportAllLists = async () => {
		const zip = new JSZip();
		lists.forEach(async (list) => {
			const csv = Papa.unparse(list.items);
			zip.file(`${list.name}.csv`, csv);
		});
		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `kel lists`);
		});
	};

	const updateStatus = useCallback((status: any) => {
		setStatus(status as ListStatus);
	}, []);

	const updateSchool = useCallback((school: string | null) => {
		setSchool(school);
	}, []);

	const updateLevel = useCallback((level: string | null) => {
		setLevel(level || '');
	}, []);

	return (
		<PaperContainer>
			<FormControl variant="outlined" className={classes.groupBy}>
				<InputLabel id={'sort-label'}>{t('common:groupBy')}</InputLabel>

				<Select
					fullWidth={true}
					labelId="sort-label"
					id="groupBy"
					value={groupBy}
					label={t('common:groupBy')}
					onChange={(ev) => {
						setGroupBy(ev.target.value as 'school' | 'level');
					}}
				>
					<MenuItem value="school">{t(`common:school`)}</MenuItem>
					<MenuItem value="level">{t(`common:level`)}</MenuItem>
				</Select>
			</FormControl>

			<Container className={classes.form}>
				<FormControl variant="outlined">
					<InputLabel id={'status-label'}>{t('common:status')}</InputLabel>

					<Select
						labelId="status-label"
						id="status"
						value={status}
						label={t('common:status')}
						onChange={(ev) => {
							updateStatus(ev.target.value);
						}}
					>
						{listStatus.map((status) => {
							return (
								<MenuItem key={`status-${status}`} value={status}>
									{t(`common:${status}`)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>

				<FormControl variant="outlined">
					<InputLabel id={'status-label'}>{t('common:level')}</InputLabel>

					<Select
						className={classes.level}
						labelId="level-label"
						id="level"
						value={level}
						label={t('common:status')}
						onChange={(ev) => {
							updateLevel((ev.target.value as string) || null);
						}}
					>
						<MenuItem value="">{t(`common:all`)}</MenuItem>

						{levelsFilters.map((level) => {
							return (
								<MenuItem key={`level-${level}`} value={level}>
									{t(`common:${level}`)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>

				{school && (
					<InputContainer title={t('common:school')} className={classes.schoolLabelContainer}>
						<Box className={classes.schoolLabel}>
							<Typography>
								{schools.find((current) => current.hubspotId === school)?.name ?? t('common:without')}
							</Typography>
							<CloseIcon onClick={() => updateSchool(null)} />
						</Box>
					</InputContainer>
				)}

				{!school && (
					<Autocomplete
						fullWidth={false}
						className={classes.autocomplete}
						onChange={(_, value) => updateSchool(value?.hubspotId ?? null)}
						id="combo-box-schools"
						options={schools}
						getOptionLabel={(school: School) => school.name}
						renderInput={(params) => <TextField {...params} label={t('common:school')} variant="outlined" />}
					/>
				)}
			</Container>

			<Container className={classes.form}>
				<FormControl variant="outlined" className={classes.select}>
					<DatePicker
						onChange={setFrom}
						value={from}
						maxDate={to || undefined}
						label={t('common:from')}
						format="DD/MM/yyyy"
						required={false}
					/>
					{!!from && (
						<CloseRoundIcon
							className={classes.clearSelect}
							onClick={() => {
								setFrom(null);
							}}
						/>
					)}
				</FormControl>

				<FormControl variant="outlined" className={classes.select}>
					<DatePicker
						onChange={setTo}
						value={to}
						minDate={from || undefined}
						label={t('common:upTo')}
						format="DD/MM/yyyy"
						required={false}
					/>
					{!!to && (
						<CloseRoundIcon
							className={classes.clearSelect}
							onClick={() => {
								setTo(null);
							}}
						/>
					)}
				</FormControl>
			</Container>

			<SearchToolbar
				onSearch={(value) => setText(value)}
				live={false}
				disabled={disabled}
				customAction={{
					'aria-label': t('common:export'),
					title: t('common:export'),
					icon: ExportIcon,
					disabled: disabled,
					onClick: () => setShowExportModal(true),
				}}
				onRefetch={() => refetch()}
				defaultValue={textFromQuery}
			/>

			{groupBy === 'school' && (
				<Schools
					lists={lists}
					loading={loading}
					onSchoolSelected={setSchool}
					status={status}
					schools={data as SchoolLists[]}
					onSelect={props.onSelect}
					onLevelSelected={setLevel}
					onRefetch={refetch}
				/>
			)}

			{groupBy === 'level' && (
				<Levels
					levels={data as LevelLists[]}
					loading={loading}
					onSchoolSelected={setSchool}
					status={status}
					onSelect={props.onSelect}
					onLevelSelected={setLevel}
					onRefetch={refetch}
				/>
			)}

			<Modal open={showExportModal} onClose={closeModal} className={classes.modal}>
				<PaperContainer>
					<Box className={classes.modalChild}>
						<Typography variant="h4">{t('lists:exportTitle')}</Typography>
						{!csvFiles.length && <Typography variant="body2">{t('lists:exportDescription')}</Typography>}
						{csvFiles.length > 0 && (
							<List>
								{csvFiles.map((csvFile) => {
									return (
										<ListItem key={`file-export-${csvFile.name}`}>
											{/* <ListItemAvatar>
											<Avatar>
												<DownloadIcon />
											</Avatar>
										</ListItemAvatar> */}
											<ListItemText primary={`${csvFile.name}.csv`} />
											<ListItemSecondaryAction>
												<FileLink csvFile={csvFile} />
											</ListItemSecondaryAction>
										</ListItem>
									);
								})}
							</List>
						)}
						{loading && <CircularProgress />}
					</Box>
					<Box className={classes.actions}>
						<Box>
							{csvFiles.length > 0 && (
								<Button
									endIcon={<DownloadIcon />}
									onClick={exportAllLists}
									color="primary"
									className={classes.zipButton}
								>
									{t('lists:downloadAll')}
								</Button>
							)}
						</Box>
						<Box>
							<Button onClick={closeModal} color="secondary">
								{t('common:cancel')}
							</Button>
							<Button onClick={confirmExport} color="primary" disabled={loading || csvFiles.length > 0}>
								{t('common:ok')}
							</Button>
						</Box>
					</Box>
				</PaperContainer>
			</Modal>
		</PaperContainer>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		searchContainer: {
			padding: theme.spacing(3),
		},
		button: {
			maxWidth: '200px',
			marginTop: theme.spacing(1),
		},
		modal: {
			position: 'absolute',
			width: '100%',
			height: 350,
			maxWidth: '600px',
			margin: 'auto',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		modalChild: {
			padding: theme.spacing(2),
			height: 250,
			overflow: 'auto',
		},
		actions: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		level: {
			minWidth: '12em',
		},
		schoolLabelContainer: {
			minWidth: '30em',
		},
		schoolLabel: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
		},
		autocomplete: {
			minWidth: '30em',
		},
		form: {
			marginBottom: '8px',
			marginTop: '16px',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			marginLeft: 0,
			gap: 16,
		},
		groupBy: {
			display: 'flex',
			flexDirection: 'row',
			margin: '20px',
		},
		select: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 4,
		},
		clearSelect: {
			cursor: 'pointer',
		},
		zipButton: {
			alignSelf: 'start',
		},
	})
);

function isValidStatus(status: any): status is ListStatus {
	return typeof status === 'string' && listStatus.includes(status as ListStatus);
}

// Function to download data to a file
function FileLink({ csvFile: { file: data, name: filename } }: { csvFile: ExportFile }) {
	var file = new Blob([data], { type: 'text/csv' });
	return (
		<a href={URL.createObjectURL(file)} download={filename}>
			<DownloadIcon />
		</a>
	);
}
