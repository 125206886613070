import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListStatus } from 'teachers-types';

import { TableHead, TableRow, TableCell, TableContainer, Table, TableBody, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ItemsModal from './ItemsModal';
import ListCells from './ListCells';

export type SchoolLevelList = {
	name: string;
	lists: List[];
};

export type SchoolLists = {
	id: string;
	name: string;
	levels: SchoolLevelList[];
};

type Props = {
	lists: List[];
	loading: boolean;
	status: ListStatus;
	schools: SchoolLists[];
	onSchoolSelected: (hubspotSchoolId: string) => any;
	onLevelSelected: (level: string) => any;
	onSelect?: (list: List) => any;
	onRefetch: () => any;
};

type SchoolRowlProps = {
	onSelect?: (list: List) => any;
	list: List;
	levelIndex: number;
	schoolIndex: number;
	listIndex: number;
	school: SchoolLists;
	onSchoolSelected: (hubspotSchoolId: string) => any;
	onLevelSelected: (level: string) => any;
	level: SchoolLevelList;
	onRefetch: () => any;
};

function Schools({ schools, onSchoolSelected, onLevelSelected, onSelect, onRefetch }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={classes.maxWidthCell}>{t('common:school')}</TableCell>
						<TableCell>{t('common:level')}</TableCell>
						<TableCell>{t('common:name')}</TableCell>
						<TableCell>{t('common:email')}</TableCell>
						{/* <TableCell>{t('common:createdAt')}</TableCell> */}
						<TableCell>{t('common:date')}</TableCell>
						<TableCell>{t('common:actions')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{schools.map((school, schoolIndex) => {
						return school.levels.map((level, levelIndex) =>
							level.lists.map((list, listIndex) => {
								return (
									<SchoolRow
										key={`school-${schoolIndex}-${level}-${levelIndex}-list-${listIndex}`}
										level={level}
										levelIndex={levelIndex}
										list={list}
										listIndex={listIndex}
										school={school}
										schoolIndex={schoolIndex}
										onLevelSelected={onLevelSelected}
										onSchoolSelected={onSchoolSelected}
										onSelect={onSelect}
										onRefetch={onRefetch}
									/>
								);
							})
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function SchoolRow({
	onSelect,
	levelIndex,
	schoolIndex,
	listIndex,
	school,
	level,
	list,
	onLevelSelected,
	onSchoolSelected,
	onRefetch,
}: SchoolRowlProps) {
	const classes = useStyles();

	const [showing, setShowing] = useState(false);

	const schoolAmount = school.levels.reduce((total, current) => total + current.lists.length, 0);
	const levelAmount = level.lists.length;

	return (
		<>
			<TableRow key={`${levelIndex}-${schoolIndex}-${listIndex}`}>
				{listIndex === 0 && levelIndex === 0 && (
					<TableCell rowSpan={schoolAmount} className={classes.rowSpan}>
						<Button onClick={() => onSchoolSelected(school.id)}>
							<strong>{school.name || 'Unknown'}</strong>
						</Button>
					</TableCell>
				)}
				{listIndex === 0 && (
					<TableCell rowSpan={levelAmount} className={classes.rowSpan}>
						<Button onClick={() => onLevelSelected(list.level!)}>
							<strong>{list.level || 'Unknown'}</strong>
						</Button>
					</TableCell>
				)}
				<ListCells list={list} setShowing={setShowing} onSelect={onSelect} onRefetch={onRefetch} />
			</TableRow>

			<ItemsModal list={list} onClose={() => setShowing(false)} open={showing} />
		</>
	);
}
export default Schools;

const useStyles = makeStyles((theme) =>
	createStyles({
		maxWidthCell: {
			maxWidth: '10em',
		},
		actions: {
			display: 'flex',
			flexDirection: 'row',
			gap: 2,
		},
		rowSpan: {
			maxWidth: '10em',
			verticalAlign: 'unset',
		},
	})
);
