import { API_DOMAIN, LEVELMAP_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchLevelMap(): Promise<LevelMapType[]> {
	try {
		const response = await api.get<LevelMapType[]>(`${API_DOMAIN}${LEVELMAP_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
