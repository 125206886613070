import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListStatus } from 'teachers-types';

import { TableHead, TableRow, TableCell, TableContainer, Table, TableBody, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ItemsModal from './ItemsModal';
import ListCells from './ListCells';

export type LevelSchoolLists = {
	id: string;
	name: string;
	lists: List[];
};

export type LevelLists = {
	name: string;
	schools: LevelSchoolLists[];
};

type Props = {
	levels: LevelLists[];
	loading: boolean;
	status: ListStatus;
	onSelect?: (list: List) => any;
	onSchoolSelected: (hubspotSchoolId: string) => any;
	onLevelSelected: (level: string) => any;
	onRefetch: () => any;
};

type LevelProps = {
	onSelect?: (list: List) => any;
	list: List;
	levelIndex: number;
	schoolIndex: number;
	listIndex: number;
	school: LevelSchoolLists;
	onSchoolSelected: (hubspotSchoolId: string) => any;
	onLevelSelected: (level: string) => any;
	level: LevelLists;
	onRefetch: () => any;
};

function Levels({ levels, onSchoolSelected, onLevelSelected, onSelect, onRefetch }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className={classes.maxWidthCell}>{t('common:level')}</TableCell>
						<TableCell>{t('common:school')}</TableCell>
						<TableCell>{t('common:name')}</TableCell>
						<TableCell>{t('common:email')}</TableCell>
						{/* <TableCell>{t('common:createdAt')}</TableCell> */}
						<TableCell>{t('common:date')}</TableCell>
						<TableCell>{t('common:actions')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{levels.map((level, levelIndex) => {
						return level.schools.map((school, schoolIndex) => {
							return school.lists.map((list, listIndex) => {
								return (
									<Level
										key={`level-${levelIndex}-list-${listIndex}`}
										level={level}
										levelIndex={levelIndex}
										list={list}
										listIndex={listIndex}
										school={school}
										schoolIndex={schoolIndex}
										onLevelSelected={onLevelSelected}
										onSchoolSelected={onSchoolSelected}
										onSelect={onSelect}
										onRefetch={onRefetch}
									/>
								);
							});
						});
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function Level({
	onSelect,
	list,
	levelIndex,
	schoolIndex,
	listIndex,
	level,
	school,
	onSchoolSelected,
	onLevelSelected,
	onRefetch,
}: LevelProps) {
	const classes = useStyles();

	const [showing, setShowing] = useState(false);

	const levelAmount = level.schools.reduce((total, current) => total + current.lists.length, 0);

	const schoolAmount = school.lists.length;

	return (
		<>
			<TableRow key={`${levelIndex}-${schoolIndex}-${listIndex}`}>
				{listIndex === 0 && schoolIndex === 0 && (
					<TableCell rowSpan={levelAmount} className={classes.rowSpan}>
						<Button onClick={() => onLevelSelected(list.level!)}>
							<strong>{list.level || 'Unknown'}</strong>
						</Button>
					</TableCell>
				)}
				{listIndex === 0 && (
					<TableCell rowSpan={schoolAmount} className={classes.rowSpan}>
						<Button onClick={() => onSchoolSelected(school.id)}>
							<strong>{school.name || 'Unknown'}</strong>
						</Button>
					</TableCell>
				)}
				<ListCells list={list} setShowing={setShowing} onSelect={onSelect} onRefetch={onRefetch} />
			</TableRow>

			<ItemsModal list={list} onClose={() => setShowing(false)} open={showing} />
		</>
	);
}

export default Levels;

const useStyles = makeStyles((theme) =>
	createStyles({
		maxWidthCell: {
			maxWidth: '10em',
		},
		paddingCell: {
			maxWidth: '10em',
		},
		rowSpan: {
			maxWidth: '10em',
			verticalAlign: 'unset',
		},
	})
);
