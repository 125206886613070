import { API_DOMAIN, SEARCH_ITEMS_URL, ITEMS_URL, ADMIN_URL } from 'config/constants';
import { api, importDataRequest } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchItems(search: ItemsSearchParameters): Promise<FetchItemsResponse> {
	try {
		const response = await api.get<FetchItemsResponse>(`${API_DOMAIN}${SEARCH_ITEMS_URL}`, { params: search });
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return {
			count: 0,
			data: [],
			filters: [],
			limit: search.limit,
			offset: search.offset,
		};
	}
}

export async function fetchItem(id: string): Promise<Item | null> {
	try {
		const response = await api.get<Item | null>(`${API_DOMAIN}${ITEMS_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function fetchSeries(): Promise<string[]> {
	try {
		const response = await api.get<string[]>(`${API_DOMAIN}${ITEMS_URL}/list/series`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function updateItem(item: Item): Promise<Item | Item[]> {
	if (!item._id) {
		delete item._id;
	}

	const url = item._id ? `${API_DOMAIN}${ITEMS_URL}/${item._id}` : `${API_DOMAIN}${ITEMS_URL}`;

	const response = await (item._id ? api.patch<Item>(url, item) : api.post<Item[]>(url, [item]));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || item;
}

export async function removeItems(items: Item[]): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${ITEMS_URL}/`;

	const response = await api.delete<{ success: boolean }>(url, { data: items });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || false;
}

export async function fakeItemsRequest(): Promise<Item[]> {
	const url = `${API_DOMAIN}${ADMIN_URL}/simulator/mock/items`;

	const response = await api.put<Item[]>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || [];
}

export function importData(file: File): Promise<ParseCsvFileResult<Item>> {
	const url = `${API_DOMAIN}${ITEMS_URL}/import`;
	return importDataRequest(url, file);
}

export async function syncStatus(): Promise<SyncStatus> {
	const url = `${API_DOMAIN}${ITEMS_URL}/sync/status`;
	const response = await api.get<SyncStatus>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || { status: 'idle', lastRun: null };
}

export async function syncData(): Promise<ParseCsvFileResult<Item>> {
	const url = `${API_DOMAIN}${ITEMS_URL}/sync`;
	const response = await api.post<ParseCsvFileResult<Item>>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return (
		response.data || {
			elements: [],
			errors: [],
			success: false,
			warnings: [],
			error: 'The sync process failed',
		}
	);
}
